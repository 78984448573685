import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import WellnessExamsInfo from "../../components/services/WellnessExamsInfo";

const WellnessExams = () => {
  return (
    <Layout>
      <PageHeader text="Wellness Exams" />
      <ServicesHeader>
        Wellness exams help your doctor detect health problems before they
        become serious or prevent health complications entirely. If you’re
        seeking an experienced primary care physician to help you maintain
        optimal health, trust the expert internal medicine team at Sunstate
        Medical Associates in Lake Mary, Florida, with your care. They offer
        physical exams, health screenings, vaccinations, disease management, and
        more. Schedule your next wellness exam with Sunstate Medical Associates
        over the phone or book online today.
      </ServicesHeader>
      <WellnessExamsInfo />
      <Conditions />
    </Layout>
  );
};

export default WellnessExams;
