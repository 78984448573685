import React from "react";
import RequestButton from "../RequestButton";

const WellnessExamsInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          Wellness Exams Q & A
        </p>

        <p className="text-xl text-gray-800 mb-2">What are wellness exams?</p>
        <p className="text-md text-gray-600 mb-2">
          Wellness exams at Sunstate Medical Associates help you stay well or
          detect medical problems early before they become serious. Wellness
          services include:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Medicare wellness visits</li>
          <li>Well woman exams</li>
          <li>Men’s wellness exams</li>
          <li>Health screenings</li>
          <li>Chronic disease management</li>
          <li>Vaccinations</li>
          <li>Health education</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          While you might not exhibit symptoms of an illness or disease, routine
          health screenings and preventive medicine can keep you healthy and
          reduce the risk of medical complications.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What happens during wellness exams?
        </p>
        <p className="text-md text-gray-600 mb-2">
          During a wellness exam, your Sunstate Medical Associates provider
          checks your weight, pulse, blood pressure, and other vital signs. They
          ask about your personal and family medical history, symptoms you’re
          experiencing, medications you take, and past diagnoses and surgeries.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Your doctor completes a comprehensive exam, including a pelvic exam
          and Pap test in women and prostate screening in men if necessary. They
          examine your eyes, ears, throat, lungs, breathing, heart beat,
          abdomen, limbs, reflexes, and genitals. Your primary care specialist
          can screen you for certain health problems, such as:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Hormone imbalance</li>
          <li>Thyroid problems</li>
          <li>Nutrient deficiencies</li>
          <li>High cholesterol and high blood pressure</li>
          <li>Sexually transmitted diseases (STDs)</li>
          <li>Lung diseases, diabetes, and arthritis</li>
          <li>Skin problems</li>
          <li>Nutrient deficiencies</li>
          <li>Asthma and allergies</li>
          <li>Osteoporosis</li>
          <li>Heart problems</li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          They might recommend urine tests, blood tests, heart function tests,
          vision and hearing screenings, or imaging procedures to diagnose your
          condition and develop a treatment plan.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Risk factors for developing a chronic disease include smoking, being
          overweight, inactivity, poor dietary habits, stress, and family
          history of disease.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          Which treatments are available?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Wellness services and chronic disease treatments available at Sunstate
          Medical Associates include:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Diet and exercise coaching</li>
          <li>Healthy lifestyle counseling</li>
          <li>Dietary supplements</li>
          <li>Medical weight loss</li>
          <li>Medications</li>
          <li>Immunizations</li>
          <li>Birth control</li>
          <li>Hormone replacement therapy</li>
          <li>Further diagnostic testing</li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          If you have a complex medical problem requiring a specialist’s care,
          your primary care doctor refers you to a highly qualified provider in
          the area. They follow-up with your treatment to stay informed about
          your care.
        </p>
        <p className="text-md text-gray-600 mb-10">
          Don’t neglect your health, especially if you have a chronic disease or
          its risk factors. Schedule a wellness exam annually, or more often if
          your doctor suggests it, with Sunstate Medical Associates by calling
          the office or using the online booking tool.
        </p>

        <RequestButton />
      </div>
    </div>
  );
};

export default WellnessExamsInfo;
